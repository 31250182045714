import React from 'react';
import { Link } from 'react-router-dom';

export default function KycProcessStep3() {
    return (
        <React.Fragment>
            <div id="sc-banner" className="sc-banner banner-bg position-relative">
                <div className="container">
                    <div className="banner-content text-center">
                        <h1 className="banner-title wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.7s">
                            BlockStar Web 3.0 Launchpad
                        </h1>
                        <Link to="/explore" className="banner-btn wow fadeInUp black-shape-big" data-wow-delay="0.4s" data-wow-duration="0.7s" >
                            <span className="btn-text">Coming Soon</span>
                            <span className="hover-shape1"></span>
                            <span className="hover-shape2"></span>
                            <span className="hover-shape3"></span>
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
