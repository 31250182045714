import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { ethers } from "ethers";
import Web3 from "web3";

export const CHAIN_ID = 1;
export const infura_Id = "84842078b09946638c03157f83405213";

export const getRpcUrl = () => {
  return {
    // 97: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
    56: 'https://bsc-dataseed.binance.org/',
    // 5:"https://goerli.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    1:"https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 43113 : "https://api.avax-test.network/ext/bc/C/rpc"
  }[1 , 56]
}

export const getWeb3 = () => {
  let setRpc = getRpcUrl();
  return new Web3(setRpc);
}

export const RPC_URLS = {
  56: "https://bsc-dataseed.binance.org/",
  // 97: "https://data-seed-prebsc-1-s2.binance.org:8545/",
  // 5:"https://goerli.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
  1:"https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
  // 43113 : "https://api.avax-test.network/ext/bc/C/rpc"
};

export const injected = new InjectedConnector({
  supportedChainIds: [1 , 56]
})

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  qrcode: true,
  infuraId: infura_Id,
});

export const coinbaseWallet = new WalletLinkConnector({

  url: `https://mainnet.infura.io/v3/84842078b09946638c03157f83405213`,
  appName: "Blockstar App",
 supportedChainIds: [1 , 56],
 
 });


export const simpleRpcProvider = new ethers.providers.StaticJsonRpcProvider('https://mainnet.infura.io/v3/84842078b09946638c03157f83405213');
export const bscRpcProvider = new ethers.providers.StaticJsonRpcProvider('https://bsc-dataseed.binance.org/');