import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Calendar from "./pages/Calendar";
import Explore from "./pages/Explore";
import Home from "./pages/Home";
import IcoApply from "./pages/IcoApply";
import KycProcess from "./pages/KycProcess";
import KycProcessStep2 from "./pages/KycProcessStep2";
import KycProcessStep3 from "./pages/KycProcessStep3";
import NftStaking from "./pages/nftStaking/NftStaking";
import ProjectDetails from "./pages/ProjectDetails";
// import TokenStaking from "./pages/tokenStaking/TokenStaking";
import TokenStakingv2 from "./pages/tokenStakingv2/TokenStakingv2";
// import PocStaking from "./pages/pocStaking/PocStaking";
import {  ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import EmberStaking from "./pages/emberStaking/EmberStaking";
// import LPStaking from "./pages/lpStaking/LPStaking";


function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
        <ToastContainer pauseOnFocusLoss={false}/>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            {/* <Route exact path="/tokenStaking" element={<TokenStaking />} /> */}
            <Route exact path="/tokenStakingv2" element={<TokenStakingv2 />} />
            {/* <Route exact path="/pocstaking" element={<PocStaking />} /> */}
            {/* <Route exact path="/emberstaking" element={<EmberStaking />} /> */}
            <Route exact path="/nftStaking" element={<NftStaking />} />
            {/* <Route exact path="/lpstaking" element={<LPStaking />} /> */}
            <Route exact path="/kyc-process" element={<KycProcess />} />
            <Route exact path="/kyc-process-step2" element={<KycProcessStep2 />} />
            <Route exact path="/kyc-process-step3" element={<KycProcessStep3 />} />
            <Route exact path="/calendar" element={<Calendar />} />
            <Route exact path="/explore" element={<Explore />} />
            <Route exact path="/project-details" element={<ProjectDetails />} />
            <Route exact path="/ico-apply" element={<IcoApply />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </Router>

    </div>
  );
}

export default App;
