import { Interface } from 'ethers/lib/utils';
import multicallAbi from './multicall.json';
import stakeAbi from './tokenstake.json';
import tokenABi from './token.json';
import nftAbi from './nft.json';
import stakeNftAbi from './stakeNft.json';
import lpAbi from './lpabi.json';


export const multicallItf = new Interface(multicallAbi);
export const stakeItf = new Interface(stakeAbi);
export const tokenItf = new Interface(tokenABi);
export const nftItf = new Interface(nftAbi);
export const stakeNftItf = new Interface(stakeNftAbi);
export const lpItf = new Interface(lpAbi);
 

