export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}

//mainnet
// V1 Staking
export const multicallAddress = "0x5ba1e12693dc8f9c48aad8770482f4739beed696";
// export const tokenStakingAddress = "0x022c8bb07d866af73c99604bcebc40e9f8cfdb95"; 
// export const tokenAddress = "0x02137Da913f50dedf6F06D5a7668670bb0f023B9";

// v2 Staking
export const multicallAddressv2 = "0x5ba1e12693dc8f9c48aad8770482f4739beed696";
export const tokenStakingAddressv2 = "0x28c774d8d187a97fe997a3bb6c2ae6bb6122c488"; 
export const tokenAddressv2 = "0x4216f7b5833ed81ceebcdf39f5acd1e7f6f9b734";
// export const multicallAddressv2 = "0x93b90bd52630b1c75b49c92df4e2e7c43d402980";
// export const tokenStakingAddressv2 = "0xe16499db25a49a2d6aa7c9dd45d156193f3e41d5"; 
// export const tokenAddressv2 = "0x03e193f5cb753b788f36d31ce5daf899c301359f";

// export const pocStakingAddress = "0x63189252e4c685c089a12964d20764fd0f04c142"; 
// export const pocAddress = "0x585698dea2422c3e1334082fbce337079b103ac5";

// export const emberStakingAddress = "0xb1198747c25bc63bbbab1cbebfe7134c1d587171"; 
// export const emberAddress = "0x36a8fcb1f8bca02dc74eb34281de3b9143eae8e3";

//NFT Staking

//ropsten

export const nftstakingAddress = "0x7D689C15ac73Db227309dc55E75cF8Fe3d60D9D0";
export const bscMultiCall = "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116";

export const LP_STAKING_ADDRESS = "0x427da6c5791adc42cd6994b3c231ed6423b82505";
// export const MUTICALL_TEST = "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116";

export const BST_LP_ADDRESS = "0x61F49F1677Cbdf0EA3462CC2A815Eb263d7f2104"
export const WETH_USD_LP_ADDRESS = "0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852"


export const formatDate = (unixTime) => {
  try {
    let dateString = new Date(unixTime).toString();
    let startIndex = dateString.indexOf("GMT");
    let modifyDate = dateString.substring(0, startIndex);
    return modifyDate;
  }
  catch (err) {
    console.log(err.message);
  }
}

