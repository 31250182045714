import { useEffect, useState } from "react"
import { MulticallContract } from "../../../hooks/useContracts";
import { stakeNftItf } from "../../../json/interface";
import { nftstakingAddress } from '../../../hooks/constant';
import { CHAIN_ID } from "../../../hooks/connectors";
import { useWeb3React } from '@web3-react/core';
import { getContractBsc } from "../../../hooks/contractHelper";
import nftAbi from '../../../json/nft.json';




export const useAccountStats = (updater) => {
  const context = useWeb3React();
  const { chainId, account } = context;
  const [stats, setStats] = useState({
    nftAddress: [],
    tokenId: [],
    rType: [],
    uri: [],
    UserRewardEarned: 0,
    totalRewardAvalible: 0,
    totalStake: 0,
    stake_nftAddress: [],
    stake_tokenId: [],
    stake_rType: [],
    stake_uri: []
  });


  const mc = MulticallContract(56);




  useEffect(() => {

    const fetch = async () => {
      const calls = [
        [nftstakingAddress, stakeNftItf.encodeFunctionData('userElibleNFT', [account])],
        [nftstakingAddress, stakeNftItf.encodeFunctionData('UserRewardEarned', [account])],
        [nftstakingAddress, stakeNftItf.encodeFunctionData('totalRewardAvalible', [account])],
        [nftstakingAddress, stakeNftItf.encodeFunctionData('userStakedNft', [account])]
      ];

      const [, data] = await mc.aggregate(calls);
      
      setStats({
        nftAddress: (stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[0].toString().split(',') : [],
        tokenId: (stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[1].toString().split(',') : [],
        rType: (stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[2].toString().split(',') : [],
        uri: (stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userElibleNFT', data[0])[3].toString().split(',') : [],
        UserRewardEarned: stakeNftItf.decodeFunctionResult('UserRewardEarned', data[1])[0].toString() / Math.pow(10, 18),
        totalRewardAvalible: stakeNftItf.decodeFunctionResult('totalRewardAvalible', data[2])[0].toString() / Math.pow(10, 18),
        stake_nftAddress: (stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[0].toString().split(',') : [],
        stake_tokenId: (stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[1].toString().split(',') : [],
        stake_rType: (stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[2].toString().split(',') : [],
        stake_uri: (stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[0].toString() !== '') ? stakeNftItf.decodeFunctionResult('userStakedNft', data[3])[3].toString().split(',') : []

      });
    }

    if (mc && account && chainId === 56) {
      console.log('111111111')
      fetch();
    }
    else {
      setStats({
        nftAddress: [],
        tokenId: [],
        rType: [],
        uri: [],
        UserRewardEarned: 0,
        totalRewardAvalible: 0,
        totalStake: 0,
        stake_nftAddress: [],
        stake_tokenId: [],
        stake_rType: [],
        stake_uri: []
      })
    }

  }, [mc, account, chainId, updater]);

  return stats;
}



export const CheckApprove = async (nftAddress = null, userAddress = null) => {
  if (nftAddress && userAddress) {
    let uniqueAddress = nftAddress.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return Promise.all(uniqueAddress.map(async (row, index) => {
      let contract = getContractBsc(nftAbi, row);
      let check = await contract.isApprovedForAll(userAddress, nftstakingAddress);
      return { "nftAddress": row, "approve": check };

    }));
  }
  else{
    return [];
  }
}




